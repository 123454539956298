// make a global variable for most recent mapping (bool) so you know which mapping to apply (to result or to A)
// change state of toggle switch text in UI
import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'
import Shepherd from 'shepherd.js'

import { Line2, LineGeometry, LineMaterial } from 'three-fatline';
// import { MeshLine, MeshLineMaterial, MeshLineRaycast } from 'three.meshline';


// import Reveal from 'reveal.js';
// import Markdown from 'reveal.js/plugin/markdown/markdown.esm.js';

// let deck = new Reveal({
//    plugins: [ Markdown ]
// })
// deck.initialize();


// guided tour


const tour = new Shepherd.Tour({
    useModalOverlay: true,
    defaultStepOptions: {
      classes: 'shepherd-theme-arrows',
      scrollTo: true
    }
  });

if (!localStorage.getItem('viewed')){

  tour.addStep({
    id: 'example-step',
    text: 'Welcome to the Gauge Calculator — This tool allows you to create geometric forms representing binary numbers.',
    popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 15] } }]
      },
    attachTo: {
      element: '.sidebar',
      on: 'right'
    },
    classes: 'example-step-extra-class',
    buttons: [
        {
            text: 'Skip Tour',
            action: tour.cancel
        },
        {
            text: 'Next',
            action: tour.next
        }
    ]
  });

  tour.addStep({
    id: 'example-step2',
    text: 'Enter a decimal or binary number. The number is converted to "gauge" notation.',
    popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 15] } }]
      },
    attachTo: {
      element: '.form_fields',
      on: 'right'
    },
    classes: 'example-step-extra-class',
    buttons: [
     {
        text: 'Cancel Tour',
        action: tour.cancel
    },
    {
        text: 'Next',
        action: tour.next
    }
    ]
  });

  tour.addStep({
    id: 'example-step3',
    text: 'Enter another number here',
    popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 15] } }]
      },
    attachTo: {
      element: '.form_fields2',
      on: 'right'
    },
    classes: 'example-step-extra-class',
    buttons: [
        {
            text: 'Cancel Tour',
            action: tour.cancel
        },
        {
            text: 'Next',
            action: tour.next
        }
    ]
  });
  tour.addStep({
    id: 'example-step4',
    text: 'Use these buttons to perform calculations with numbers A and B. The square root and square buttons are applied to number A. Pressing an operation again will then apply the operation to number B and the result.',
    popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 15] } }]
      },
    attachTo: {
      element: '.operators',
      on: 'right'
    },
    classes: 'example-step-extra-class',
    buttons: [
        {
            text: 'Cancel Tour',
            action: tour.cancel
        },
        {
            text: 'Next',
            action: tour.next
        }
    ]
  });
  tour.addStep({
    id: 'example-step5',
    text: 'Use the pi and e buttons to display these numbers. Press C to clear the screen',
    popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 15] } }]
      },
    attachTo: {
      element: '.aux',
      on: 'right'
    },
    classes: 'example-step-extra-class',
    buttons: [
        {
            text: 'Cancel Tour',
            action: tour.cancel
        },
        {
            text: 'Next',
            action: tour.next
        }
    ]
  });
  tour.addStep({
    id: 'example-step6',
    text: 'Calculation results are shown here',
    popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 15] } }]
      },
    attachTo: {
      element: '.form_fields3',
      on: 'right'
    },
    classes: 'example-step-extra-class',
    buttons: [
        {
            text: 'Cancel Tour',
            action: tour.cancel
        },
        {
            text: 'Next',
            action: tour.next
        }
    ]
  });

  tour.addStep({
    id: 'example-step7',
    text: 'Use this switch to display or hide the grid',
    popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 15] } }]
      },
    attachTo: {
      element: '#switch1',
      on: 'right'
    },
    classes: 'example-step-extra-class',
    buttons: [
        {
            text: 'Cancel Tour',
            action: tour.cancel
        },
        {
            text: 'Next',
            action: tour.next
        }
    ]
  });

  tour.addStep({
    id: 'example-step8',
    text: 'Use this switch to animate/rotate the display',
    popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 15] } }]
      },
    attachTo: {
      element: '#switch2',
      on: 'right'
    },
    classes: 'example-step-extra-class',
    buttons: [
        {
            text: 'Cancel Tour',
            action: tour.cancel
        },
        {
            text: 'Next',
            action: tour.next
        }
    ]
  });

  tour.addStep({
    id: 'example-step8',
    text: 'Click here to learn about how binary numbers are plotted to 3D space',
    popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 15] } }]
      },
    attachTo: {
      element: '#toggleT',
      on: 'right'
    },
    classes: 'example-step-extra-class',
    buttons: [
        {
            text: 'Cancel Tour',
            action: tour.cancel
        },
        {
            text: 'Next',
            action: tour.next
        }
    ]
  });

  tour.start();
  localStorage.setItem('viewed','yes');

}


// Base
// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()



let gaugeList;
let gaugeListB;
let gaugeListC;
let gauge;
let gauge2;
let gauge3;
let cardinalCount = 1;
let gaugeValue;
let gaugeValueB;
let gaugeValueC;
let decimalField = document.getElementById("decimalA")
let decimalFieldB = document.getElementById("decimalB")
const group = new THREE.Group();
const linegroup = new THREE.Group();
const conGroup = new THREE.Group();
let line1;
let multState = false;
let addState = false;
let subState = false;
let divState = false;
let rootState = false;
let squareState = false;
let binaryFieldC = document.getElementById("binaryResult")
let calcState = false;  // keeps  track of calculation state from button press
let inputState = "A";  // keeps track of which input field is being used

// Restricts binary input to only 0s and 1s
// https://stackoverflow.com/questions/42595472/how-to-restrict-input-text-box-allow-only-0-and-1
document.getElementById('binaryA').addEventListener('keydown', function(ev) {
    if (document.getElementById("binaryA").value[0] != "-" &&  document.getElementById("binaryA").value.includes('-')){
        document.getElementById("binaryA").value = "";
    }
    if (gauge){
        if (gauge.includes('.') ){
            if(ev.keyCode != 48 && ev.keyCode != 49 && ev.keyCode != 8 && ev.keyCode != 37 && ev.keyCode != 39){
                ev.preventDefault();
            } 
        }
        else {
            if(ev.keyCode != 189 && ev.keyCode != 48 && ev.keyCode != 49 && ev.keyCode != 8 && ev.keyCode != 190 && ev.keyCode != 37 && ev.keyCode != 39) {
                ev.preventDefault();
            }
        }
    }
});


document.getElementById('binaryB').addEventListener('keydown', function(ev) {
    if (document.getElementById("binaryB").value[0] != "-" &&  document.getElementById("binaryB").value.includes('-')){
        document.getElementById("binaryB").value = "";
    }
    if (gauge2){
        if (gauge2.includes('.') ){
            if(ev.keyCode != 48 && ev.keyCode != 49 && ev.keyCode != 8  && ev.keyCode != 37 && ev.keyCode != 39 ){
                ev.preventDefault();
            } 
        }
        else {
            if(ev.keyCode != 189 && ev.keyCode != 48 && ev.keyCode != 49 && ev.keyCode != 8 && ev.keyCode != 190  && ev.keyCode != 37 && ev.keyCode != 39) {
                ev.preventDefault();
            }
        }
    }

});


// const size = 20;
// const divisions = 20;

// const gridHelper = new THREE.GridHelper( size, divisions );
// scene.add( gridHelper );

// const gridHelper2 = new THREE.GridHelper( size, divisions );
// gridHelper2.rotation.x = Math.PI / 2;
// scene.add( gridHelper2 );

// const gridHelper3 = new THREE.GridHelper( size, divisions );
// gridHelper3.rotation.z = Math.PI / 2;
// scene.add( gridHelper3 );





// Lights
const lights = [];
const lightValues = [
    {colour: 0x0, intensity: 8, dist: 12, x: 1, y: 0, z: 8},
    {colour: 0x0, intensity: 6, dist: 12, x: -2, y: 1, z: -10},
    {colour: 0x0, intensity: 3, dist: 10, x: 0, y: 10, z: 1},
    {colour: 0x0, intensity: 6, dist: 12, x: 0, y: -10, z: -1},
    {colour: 0xfffefc, intensity: 6, dist: 12, x: 10, y: 3, z: 0},
    {colour: 0xfffefc, intensity: 6, dist: 12, x: -10, y: -1, z: 0}
];
for (let i=0; i<6; i++) {
    lights[i] = new THREE.PointLight(
        lightValues[i]['colour'], 
        lightValues[i]['intensity'], 
        lightValues[i]['dist']);
    lights[i].position.set(
        lightValues[i]['x'], 
        lightValues[i]['y'], 
        lightValues[i]['z']);
    scene.add(lights[i]);
}

// Axes 
const geometrywhite = new THREE.CylinderGeometry( .02, .02, 100, 10 );
const materialwhite = new THREE.MeshBasicMaterial( {color: 0x85b3ff, transparent: true,  opacity: 0.3} );




function gridToggle() {

    for (let i = -50; i < 51; i+=1)
    {
        line1 = new THREE.Mesh( geometrywhite, materialwhite ); // line
        line1.position.x = i
        line1.position.z = 0
        linegroup.add( line1 ); 
    }

    for (let p = -50; p < 51; p += 1)
    {
        if (p != 0){
            line1 = new THREE.Mesh( geometrywhite, materialwhite ); // line
            line1.position.z = p
            line1.rotation.z = Math.PI/2
            line1.rotation.x= Math.PI/8
            linegroup.add( line1 ); 

        }
    }


    for (let j = -50; j < 51; j+=1)
    {
        
        line1 = new THREE.Mesh( geometrywhite, materialwhite ); // line
        line1.position.y = 0
        line1.position.z = j
        linegroup.add( line1 ); 
    }



    for (let k = -50; k < 51; k+=1)
    {
        line1 = new THREE.Mesh( geometrywhite, materialwhite ); // line
        line1.position.x = k
        line1.rotation.x = Math.PI/2
        line1.position.z = 0
        linegroup.add( line1 ); 
    }

    for (let l = -50; l < 51; l+=1)
    {
        line1 = new THREE.Mesh( geometrywhite, materialwhite ); // line
        line1.position.y = l
        line1.rotation.x = Math.PI/2
        line1.position.z = 0
        linegroup.add( line1 ); 
    }

    for (let m = -50; m < 51; m+=1)
    {
        line1 = new THREE.Mesh( geometrywhite, materialwhite ); // line
        line1.position.y = m
        line1.rotation.z = Math.PI/2
        line1.position.z = 0
        linegroup.add( line1 ); 
    }

    scene.add(linegroup);

}

// controls.autoRotate = true;

let animation = document.getElementById("animation")
let aniState = false
animation.onclick = function(){
    if (animation.checked && aniState == false) {
        controls.autoRotate = true;
        aniState = true

    } else {
        controls.autoRotate = false;
        aniState = false;
    }
};




// var switchStatus = false;

// $("#flexSwitchCheckDefault").on('change', function() {
//     if ($(this).is(':checked')) {
//         switchStatus = $(this).is(':checked');
//         gridToggle()
//     }
//     else {
//        switchStatus = $(this).is(':checked');
//     }
// });

let toggleGrid = document.getElementById("flexSwitchCheckDefault")
let gridState = false
toggleGrid.onclick = function(){
    if (toggleGrid.checked && gridState == false) {
        gridToggle();
        gridState = true;

    } else {
        linegroup.remove(...linegroup.children)
        // console.log('Toggle is not checked')
        gridState = false;
    }
};

let toggleMapping = document.getElementById("switchMapping")
let dotState = false
toggleMapping.onclick = function(){
    if (toggleMapping.checked && dotState == false) {
        dotState = true;
        // console.log("dotState = true")
        if(inputState == "A" && gauge){
            group.remove(...group.children)
            conGroup.remove(...conGroup.children);
            console.log(gauge.reverse());
            mapping(gauge)
        }else if (inputState == "C" && gauge3){
            group.remove(...group.children)
            conGroup.remove(...conGroup.children);
            mapping(gauge3.reverse())
        }
    } else {
        dotState = false;
        console.log("dotState = false")
        if(inputState == "A" && gauge){
            group.remove(...group.children)
            conGroup.remove(...conGroup.children);
            console.log(gauge.reverse());
            mapping(gauge)
        }else if (inputState == "C" && gauge3){
            conGroup.remove(...conGroup.children);
            group.remove(...group.children)
            mapping(gauge3.reverse())
        }
    }
};




// Axes 
const geometryblue = new THREE.CylinderGeometry( .04, .04, 100, 10 );
const materialblue = new THREE.MeshBasicMaterial( {color: 0x2660A4, transparent: true, opacity: 0.8} );
const cylinder = new THREE.Mesh( geometryblue, materialblue );
cylinder.position.x = 0
cylinder.position.z = 0
scene.add( cylinder );

const geometryred = new THREE.CylinderGeometry( .04, .04, 100, 10 );
const materialred = new THREE.MeshBasicMaterial( {color: 0xff2e2f, transparent: true, opacity: 0.8} );
const cylinder2 = new THREE.Mesh( geometryred, materialred );
cylinder2.position.x = 0
cylinder2.position.z = 0
cylinder2.rotation.x = Math.PI/2
scene.add( cylinder2 );

const geometrygreen = new THREE.CylinderGeometry( .04, .04, 100, 10 );
const materialgreen = new THREE.MeshBasicMaterial( {color: 0xffef36, transparent: true, opacity: 0.8} );
const cylinder3 = new THREE.Mesh( geometrygreen, materialgreen );
cylinder3.position.x = 0
cylinder3.position.z = 0
cylinder3.rotation.z = Math.PI/2
scene.add( cylinder3 );




// for connectors

const geometryblueC = new THREE.CylinderGeometry( .04, .04, 100, 10 );
const materialblueC = new THREE.MeshBasicMaterial( {color: 0x2660A4, transparent: true, opacity: 0.8} );
const cylinderC = new THREE.Mesh( geometryblue, materialblue );


const geometryredC = new THREE.CylinderGeometry( .04, .04, 100, 10 );
const materialredC = new THREE.MeshBasicMaterial( {color: 0xff2e2f, transparent: true, opacity: 0.8} );
const cylinder2C = new THREE.Mesh( geometryred, materialred );


const geometrygreenC = new THREE.CylinderGeometry( .04, .04, 100, 10 );
const materialgreenC = new THREE.MeshBasicMaterial( {color: 0xffef36, transparent: true, opacity: 0.8} );
const cylinder3C = new THREE.Mesh( geometrygreen, materialgreen );




// Dimensions for canvas
const sizes = {
    width: window.innerWidth * .65,
    height: window.innerHeight
}




// Resize
window.addEventListener('resize', () =>
{

    if (window.innerWidth < 510) {

        canvas.style.width = window.innerWidth + 'px';
        canvas.style.height = window.innerHeight * .5 + 'px';
        // Update sizes
        sizes.width = window.innerWidth
        sizes.height = window.innerHeight * .6
        // Update camera
        camera.aspect = sizes.width / sizes.height
        camera.updateProjectionMatrix()
        // Update renderer
        renderer.setSize(sizes.width, sizes.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 3))
        tour.cancel()

    } else{
    // Resize the calculator explicitly.
        canvas.style.width = window.innerWidth * 0.65 + 'px';
        canvas.style.height = window.innerHeight + 'px';
        // Update sizes
        sizes.width = window.innerWidth * 0.65 
        sizes.height = window.innerHeight;
        // Update camera
        camera.aspect = sizes.width / sizes.height
        camera.updateProjectionMatrix()
        // Update renderer
        renderer.setSize(sizes.width, sizes.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 3))
    }
})









// Debugging controls

// const gui = new dat.GUI()
// gui.close();
// gui.add(group.position, 'x', - 9, 1)

// camera set up
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.01, 600)
camera.position.x = 100
camera.position.y = -10
camera.position.z = -20
camera.lookAt(0,0,0)
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
controls.minDistance = 3;
controls.maxDistance = 320;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
})

if (window.innerWidth < 510) {
    canvas.style.width = window.innerWidth + 'px';
    canvas.style.height = window.innerHeight * .6 + 'px';
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight * .6
    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()
    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 3))
    tour.cancel()
    

} else{
    canvas.style.width = window.innerWidth * 0.65 + 'px';
    canvas.style.height = window.innerHeight + 'px';
    // Update sizes
    sizes.width = window.innerWidth * 0.65 
    sizes.height = window.innerHeight;
    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()
    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 3))
}

renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.setClearColor (0x31393C, 1);    // set background color

/**
 * Animate
 */
const clock = new THREE.Clock()


const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    // Update controls
    controls.update()
    // Render
    renderer.render(scene, camera)
    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

// functionality when decimal field is changed
decimalField.oninput = () => {
    count = 0;
    multState = false;
    addState = false;
    subState = false;
    divState = false;
    rootState = false;
    squareState = false;
    inputState = "A";
    // scene.remove( plane1, plane2, plane3, plane4, plane5, plane6);
    let decimalValue = parseFloat(decimalField.value)   // get value of decimal field - turns into a float
    let binaryValue = decimalValue.toString(2)    // convert decimal to binary
    //  prevents "not a number" values

    if(decimalValue){
        // if theres a decimal value
        document.getElementById("binaryA").value = binaryValue;  // set value of binary field to binary value
    }
    else{
        document.getElementById("binaryA").value = "";    // else set value of binary field to empty
    }
    gaugeConversion()  // runs the gauge conversion function
    document.getElementById("gaugeA").value = gaugeValue;  // set value of gauge field to gauge value
    group.remove(...group.children)
    console.log(gauge)
    mapping(gauge)    // runs the function to map the values
    // console.log(gaugeValue)
}



let binaryField = document.getElementById("binaryA")
// selects the binary field 

// listener/function for when the binary field is changed
binaryField.oninput = () => {
    multState = false;
    addState = false;
    subState = false;
    divState = false;
    rootState = false;
    squareState = false;
    inputState = "A";
    // scene.remove( plane1, plane2, plane3, plane4, plane5, plane6); // clears the scene
    let binaryValue = binaryField.value.parseBinary()  // get value of binary field - turns into a decimal string

    let decimalValue = binaryValue

    // if binary field is not empty
    if(binaryValue){
        document.getElementById("decimalA").value = decimalValue;  // set value of decimal field to decimal value
    }
    else{
        document.getElementById("decimalA").value = "";  // else set value of decimal field to empty
    }
    gaugeConversion()  // runs the gauge conversion function
    document.getElementById("gaugeA").value = gaugeValue; // set value of gauge field to gauge value
    // mapping()  // runs the function to map the values

    group.remove(...group.children)
    mapping(gauge)    // runs the function to map the values
    // console.log(gaugeValue)
}




let binaryFieldB = document.getElementById("binaryB")

// listener/function for when the binary field is changed
binaryFieldB.oninput = () => {
    multState = false;
    addState = false;
    subState = false;
    divState = false;
    rootState = false;
    squareState = false;
    
    // scene.remove( plane1, plane2, plane3, plane4, plane5, plane6); // clears the scene
    let binaryValueB = binaryFieldB.value.parseBinary()  // get value of binary field - turns into a decimal string

    let decimalValueB = binaryValueB

    // if binary field is not empty
    if(binaryValueB){
        document.getElementById("decimalB").value = decimalValueB;  // set value of decimal field to decimal value
    }
    else{
        document.getElementById("decimalB").value = "";  // else set value of decimal field to empty
    }

    gaugeConversionB()  // runs the gauge conversion function
    // console.log(gaugeValueB)

    document.getElementById("gaugeB").value = gaugeValueB; // set value of gauge field to gauge value
    // mapping()  // runs the function to map the values
}



decimalFieldB.oninput = () => {
    multState = false;
    addState = false;
    subState = false;
    divState = false;
    rootState = false;
    squareState = false;
    // scene.remove( plane1, plane2, plane3, plane4, plane5, plane6);
    let decimalValueB = parseFloat(decimalFieldB.value)   // get value of decimal field - turns into a float
    let binaryValueB = decimalValueB.toString(2)    // convert decimal to binary
    //  prevents "not a number" values

    if(decimalValueB){
        // if theres a decimal value
        document.getElementById("binaryB").value = binaryValueB;  // set value of binary field to binary value
    }
    else{
        document.getElementById("binaryB").value = "";    // else set value of binary field to empty
    }
    gaugeConversionB()  // runs the gauge conversion function
    // console.log(gaugeValueB)
    document.getElementById("gaugeB").value = gaugeValueB;  // set value of gauge field to gauge value
}



// https://stackoverflow.com/questions/37109968/how-to-convert-binary-fraction-to-decimal

// function to convert binary to decimal
String.prototype.parseBinary = function parseBinary() {
    var radix = 2;
    var s = this.split('.');
    var decimal = parseInt(s[0], radix);
    if(s.length > 1){
      var fract = s[1].split('');
  
      for(var i = 0, div = radix; i < fract.length; i++, div = div * radix)
      {
        decimal = decimal + fract[i] / div;
      }
    }
    return decimal;
  };


// function to join list
function reverseString(str) {
    return str.split("").reverse().join("");
}

//  test geometries
// let geometry1
// let geometry2
// let geometry3
// let geometry4
// let geometry5
// let geometry6
// let geometry7
// let geometry8
// let material1
// let material2
// let material3
// let material4
// let material5
// let material6
// let material7
// let material8

// const material = new THREE.MeshBasicMaterial( {color: 0xffffff} );
// const material2 = new THREE.MeshBasicMaterial( {color: 0x1B00FF} );
// const material3 = new THREE.MeshBasicMaterial( {color: 0xfff712} );
// const material4 = new THREE.MeshBasicMaterial( {color: 0xed0505} );


// const cube = new THREE.Mesh( geometry, material ); // node
// cube.position.set(.5, .5, -.5);
// scene.add( cube );


// const cube2 = new THREE.Mesh( geometry, material2 );
// cube2.position.set(.5, -.5, -.5);
// scene.add( cube2 );

// const cube3 = new THREE.Mesh( geometry, material2 ); // node
// cube3.position.set(.5, -1.5, -.5);
// scene.add( cube3 );

// const cube4 = new THREE.Mesh( geometry, material3 );
// cube4.position.set(-.5, -1.5, -.5);
// scene.add( cube4 );

// const cube5 = new THREE.Mesh( geometry, material3 );
// cube5.position.set(-1.5, -1.5, -.5);
// scene.add( cube5 );

// const cube6 = new THREE.Mesh( geometry, material3 );  // node
// cube6.position.set(-2.5, -1.5, -.5);
// scene.add( cube6 );


// const cube7 = new THREE.Mesh( geometry, material4 ); 
// cube7.position.set(-2.5, -1.5, -1.5);
// scene.add( cube7 );

// const cube8 = new THREE.Mesh( geometry, material4 ); 
// cube8.position.set(-2.5, -1.5, -2.5);
// scene.add( cube8 );

// const cube9 = new THREE.Mesh( geometry, material4 ); 
// cube9.position.set(-2.5, -1.5, -3.5);
// scene.add( cube9 );

// const cube10 = new THREE.Mesh( geometry, material2 );
// cube10.position.set(-2.5, -.5, -3.5);
// scene.add( cube10 );

// const cube11 = new THREE.Mesh( geometry, material2 );
// cube11.position.set(-2.5, .5, -3.5);
// scene.add( cube11 );

// const cube12 = new THREE.Mesh( geometry, material2 );
// cube12.position.set(-2.5, 1.5, -3.5);
// scene.add( cube12 );

// const cube13 = new THREE.Mesh( geometry, material2 );
// cube13.position.set(-2.5, 2.5, -3.5);
// scene.add( cube13 );

// const cube14 = new THREE.Mesh( geometry, material2 );
// cube14.position.set(-2.5, 3.5, -3.5);
// scene.add( cube14 );

// const cube15 = new THREE.Mesh( geometry, material2 );
// cube15.position.set(-2.5, 4.5, -3.5);
// scene.add( cube15 );

const geometry = new THREE.BoxGeometry( 1, 1, 1 );
const materialWhite = new THREE.MeshBasicMaterial( {color: 0xffffff, transparent: true, opacity: 0.7} );
const materialBlue = new THREE.MeshBasicMaterial( {color: 0x2660A4, transparent: true, opacity: 0.95} );
const materialYellow = new THREE.MeshBasicMaterial( {color: 0xffef36, transparent: true, opacity: 0.95} );
const materialRed = new THREE.MeshBasicMaterial( {color: 0xff2e2f, transparent: true, opacity: 0.95} );

var sphereGeo = new THREE.SphereGeometry(  1, 32, 16 ); 
var sphereWhiteMaterial = new THREE.MeshBasicMaterial( {color: 0xffffff, transparent: true, opacity: 0.7} ); 
var sphereBlueMaterial = new THREE.MeshBasicMaterial( {color: 0x2660A4, transparent: true, opacity: 0.95}  ); 
var sphereYellowMaterial = new THREE.MeshBasicMaterial({color: 0xffef36, transparent: true, opacity: 0.95}  ); 
var sphereRedMaterial = new THREE.MeshBasicMaterial( {color: 0xff2e2f, transparent: true, opacity: 0.95} ); 


// // Mesh line test

// const points = [-10, 0, 0, 0, 10, 0, 10, 0, 0];
// for (let j = 0; j < Math.PI; j += 2 * Math.PI / 100) {
//   points.push(new THREE.Vector3(Math.cos(j), Math.sin(j), 0));
// }
// const geometryTest = new THREE.BufferGeometry().setFromPoints(points);
// const lineTest = new MeshLine();
// lineTest.setGeometry(geometryTest);
// lineTest.setPoints(points);

// const materialTest = new MeshLineMaterial({
//     color: new THREE.Color(0xff0000),
//     lineWidth: 0.05,
//     resolution: new THREE.Vector2(640, 480),
//     sizeAttenuation: true
// });

// const meshTest = new THREE.Mesh(lineTest.geometry, materialTest);
// scene.add(meshTest);




// const geometryTest = new LineGeometry();
// geometryTest.setPositions([-10, 0, 0, 0, 10, 0, 10, 0, 0]); // [ x1, y1, z1,  x2, y2, z2, ... ] format

// const materialTest = new LineMaterial({
//   color: 'red',
//   linewidth: 3, // px
//   resolution: new THREE.Vector2(640, 480) // resolution of the viewport
//   // dashed, dashScale, dashSize, gapSize
// });

// const myLine = new Line2(geometryTest, materialTest);

// myLine.computeLineDistances();
// myLine.scale.set( 1, 1, 1 );

// scene.add( myLine );




// import { MeshLine, MeshLineMaterial, MeshLineRaycast } from 'three.meshline';




// var blueSphere = new THREE.Mesh( sphereGeo, sphereBlueMaterial); 
// var whiteSphere = new THREE.Mesh( sphereGeo, sphereWhiteMaterial); 
// var redSphere = new THREE.Mesh( sphereGeo, sphereRedMaterial); 
// var yellowSphere = new THREE.Mesh( sphereGeo, sphereYellowMaterial); 

// sphere.position.set(0, 0, 0);
// scene.add( sphere );



// to do : issue with cube placement after decimal (too far)

//  need to fix drag orbit control

// function iterates over the gauge value and creates cube for each value
let lineRecord = [];
let mapping = (gaugetype) => {
    lineRecord = [];
    let x = -0.5;
    let y = .5;
    let z = .5;
    let gaugeReverse = gaugetype.reverse()
    let upper;
    let decimal = false;
    conGroup.remove(...conGroup.children);
    // console.log(gaugeReverse);
    // gauge reverse: ['+x', '+y', '-z', '-x', '+y', '+z', '+x', '+y', '+z', '+x']
    if (dotState == true) {
    for (let i = 0; i < gaugeReverse.length; i++)
        {
    
            if (gaugeReverse[i] == '-x' && i == 0 && decimal == false) {
                x = .5
                y = .5;
                z = .5
                plotSphere(sphereRedMaterial, x, y, z)
                lineRecord.push(x,y,z,x,y,z);
            } 
    
            if (gaugeReverse[i] == '+x' && i == 0 && decimal == false) {
                x = -.5
                y = .5;
                z = .5
                plotSphere(sphereRedMaterial, x, y, z)
                lineRecord.push(x,y,z,x,y,z);
            } 
    
            if (gaugeReverse[i] == '-z' && i == 0 && decimal == false) {
                x = .5
                y = .5;
                z = -.5
                plotSphere(sphereRedMaterial, x, y, z)
                lineRecord.push(x,y,z ,x,y,z);
            } 
    
            if (gaugeReverse[i] == '+z' && i == 0 && decimal == false) {
                x = .5
                y = .5;
                z = .5
                plotSphere(sphereYellowMaterial, x, y, z)
                lineRecord.push(x,y,z ,x,y,z);
            } 
    
            if (gaugeReverse[i] == '-y' && i == 0 && decimal == false) {
                x = .5
                y = -.5;
                z = .5
                plotSphere(sphereBlueMaterial, x, y, z)
                lineRecord.push(x,y,z,x,y,z);
            } 
    
            if (gaugeReverse[i] == '+y' && i == 0 && decimal == false) {
                x = .5
                y = .5;
                z = .5
                plotSphere(sphereBlueMaterial, x, y, z)
                lineRecord.push(x,y,z,x,y,z);
            } 
    
            // function plotCy(mType, height, rotationX, rotationY, rotationZ, xPos, yPos, zPos) {
            //     const cyGeo = new THREE.CylinderGeometry( .04, .04, height, 10 );
            //     const cy1 = new THREE.Mesh( cyGeo, mType ); // node
            //     cy1.position.x = xPos
            //     cy1.position.y = yPos
            //     cy1.position.z = zPos
            //     // cy1.rotation.x = Math.PI/2
            //     cy1.rotation.x = rotationX
            //     cy1.rotation.y = rotationY
            //     cy1.rotation.z = rotationZ
                
            //     group.add( cy1 );
            //     scene.add( cylinder2 );
            // }
            
            
            
    
            if (gaugeReverse[i] == '+x' && i != 0 && decimal == false) {
    
                upper = -(i + .5)
                // for (let j = x - 1; j >= upper ; j--) {
                // plotSphere(sphereRedMaterial,  j, y, z)
                // }
                plotSphere(sphereRedMaterial,  upper, y, z)
                // plotCy(materialredC, 20, 0, Math.PI/2, Math.PI/2, upper, y, z)
                x = upper
                lineRecord.push(x,y,z,x,y,z);
            } 
    
            if (gaugeReverse[i] == '+x' && i != 0 && decimal == true) {
    
                upper = -((i -1) + .5)
                // for (let j = x - 1; j >= upper ; j--) {
                // plotSphere(sphereRedMaterial,  j, y, z)
                // }
                plotSphere(sphereRedMaterial,  upper, y, z)
                // plotCy(materialredC, 20, 0, Math.PI/2, Math.PI/2, upper, y, z)
                x = upper
                lineRecord.push(x,y,z,x,y,z);
            } 
    
            if (gaugeReverse[i] == '-x' && i != 0 && decimal == false) {
                upper = i + .5
                // for (let j = x - -1; j <= upper ; j++) {
                //     plotSphere(sphereRedMaterial, j, y, z)
                // }
                plotSphere(sphereRedMaterial,  upper, y, z)
                // plotCy(materialredC, 20, 0, Math.PI/2, Math.PI/2, upper, y, z)
                x = upper
                lineRecord.push(x,y,z,x,y,z);
            } 
    
            if (gaugeReverse[i] == '-x' && i != 0 && decimal == true) {
                upper = (i - 1) + .5
                // for (let j = x - -1; j <= upper ; j++) {
                //     plotSphere(sphereRedMaterial, j, y, z)
                // }
                plotSphere(sphereRedMaterial,  upper, y, z)
                // plotCy(materialredC, 20, 0, Math.PI/2, Math.PI/2, upper, y, z)
                x = upper
                lineRecord.push(x,y,z,x,y,z);
            } 
    
    
            if (gaugeReverse[i] == '+y' && decimal == false) {
                upper = i + .5
                // for (let j = y + 1; j <= upper ; j++) {
                // plotSphere(sphereBlueMaterial, x, j, z)
                // }
                plotSphere(sphereBlueMaterial, x, upper, z)
                y = upper
                lineRecord.push(x,y,z,x,y,z);
            }
    
            if (gaugeReverse[i] == '+y' && decimal == true) {
                upper = (i - 1) + .5
                // for (let j = y + 1; j <= upper ; j++) {
                // plotSphere(sphereBlueMaterial, x, j, z)
                // }
                plotSphere(sphereBlueMaterial, x, upper, z)
                y = upper
                lineRecord.push(x,y,z,x,y,z);
            }
    
    
    
            if (gaugeReverse[i] == '-y' && decimal == false) {
                upper = -(i + .5)
                // for (let j = y - 1; j >= upper ; j--) {
                //     plotSphere(sphereBlueMaterial, x, j, z)
                // }
                plotSphere(sphereBlueMaterial, x, upper, z)
                y = upper
                lineRecord.push(x,y,z,x,y,z);
            }
            if (gaugeReverse[i] == '-y' && decimal == true) {
                upper = -((i - 1) + .5)
                // for (let j = y - 1; j >= upper ; j--) {
                //     plotSphere(sphereBlueMaterial, x, j, z)
                // }
                plotSphere(sphereBlueMaterial, x, upper, z)
                y = upper
                lineRecord.push(x,y,z,x,y,z);
            }
    
            if (gaugeReverse[i] == '+z' && decimal == false) {
                upper = i + .5
                // for (let j = z + 1; j <= upper ; j++) {
                // plotSphere(sphereYellowMaterial, x, y, j)
                // }
                plotSphere(sphereYellowMaterial, x, y, upper)
                z = upper
                lineRecord.push(x,y,z,x,y,z);
        }
            if (gaugeReverse[i] == '+z' && decimal == true) {
                upper = (i - 1) + .5
                // for (let j = z + 1; j <= upper ; j++) {
                // plotSphere(sphereYellowMaterial, x, y, j)
                // }
                plotSphere(sphereYellowMaterial, x, y, upper)
                z = upper
                lineRecord.push(x,y,z,x,y,z);
            }
    
            if (gaugeReverse[i] == '-z' && decimal == false) {
                upper = -(i + .5)
                // for (let j = z - 1; j >= upper ; j--) {
                // plotSphere(sphereYellowMaterial, x, y, j)
                // }
                plotSphere(sphereYellowMaterial, x, y, upper)
                z = upper
                lineRecord.push(x,y,z,x,y,z);
            }
    
            if (gaugeReverse[i] == '-z' && decimal == true) {
                upper = -((i - 1) + .5)
                // for (let j = z - 1; j >= upper ; j--) {
                // plotSphere(sphereYellowMaterial, x, y, j)
                // }
                plotSphere(sphereYellowMaterial, x, y, upper)
                z = upper
                lineRecord.push(x,y,z,x,y,z);
            }
    
            if (gaugeReverse[i] == '.' && decimal == false) {
                plotSphere(sphereWhiteMaterial, x, y, z)
                decimal = true;
                // lineRecord.push(x,y,z,x,y,z);
            }   
        scene.add( group );
    }
    // console.log(lineRecord);
    // Mesh line test

// const points = lineRecord.reverse();
// const geometryTest = new THREE.BufferGeometry().setFromPoints(points);
// const lineTest = new MeshLine();
// lineTest.setGeometry(geometryTest);
// lineTest.setPoints(points);

// const materialTest = new MeshLineMaterial({
//     color: new THREE.Color(0xcaeefa),
//     lineWidth: 0.3,
//     resolution: new THREE.Vector2(640, 480),
//     sizeAttenuation: true,
//     transparent: true,
//     opacity: 0.7
// });

// const meshTest = new THREE.Mesh(lineTest.geometry, materialTest);
// scene.add(meshTest);


// const geometryTest2 = new LineGeometry();
// geometryTest2.setPositions(lineRecord.reverse()); // [ x1, y1, z1,  x2, y2, z2, ... ] format

// const matLine = new LineMaterial({
//   color: '#c7f0ff',
//   linewidth: .5, // px
//   worldUnits: true,
//   resolution: new THREE.Vector2(1000, 480), 
//   transparent: true,
//   opacity: 0.0
//   // dashed, dashScale, dashSize, gapSize
// });

// const myLine = new Line2(geometryTest2, matLine);
// myLine.scale.set( 1, 1, 1 );
// conGroup.add(myLine);
// scene.add(conGroup);

} else{
    for (let i = 0; i < gaugeReverse.length; i++)
    {

        if (gaugeReverse[i] == '-x' && i == 0 && decimal == false) {
            x = .5
            y = .5;
            z = .5
            plotCube(materialRed, x, y, z)
        } 

        if (gaugeReverse[i] == '+x' && i == 0 && decimal == false) {
            x = -.5
            y = .5;
            z = .5
            plotCube(materialRed, x, y, z)
        } 

        if (gaugeReverse[i] == '-z' && i == 0 && decimal == false) {
            x = .5
            y = .5;
            z = -.5
            plotCube(materialYellow, x, y, z)
        } 

        if (gaugeReverse[i] == '+z' && i == 0 && decimal == false) {
            x = .5
            y = .5;
            z = .5
            plotCube(materialYellow, x, y, z)
        } 

        if (gaugeReverse[i] == '-y' && i == 0 && decimal == false) {
            x = .5
            y = -.5;
            z = .5
            plotCube(materialBlue, x, y, z)
        } 

        if (gaugeReverse[i] == '+y' && i == 0 && decimal == false) {
            x = .5
            y = .5;
            z = .5
            plotCube(materialBlue, x, y, z)
        } 

        

        if (gaugeReverse[i] == '+x' && i != 0 && decimal == false) {

            upper = -(i + .5)
            for (let j = x - 1; j >= upper ; j--) {
            plotCube(materialRed,  j, y, z)
            }
            x = upper
        } 

        if (gaugeReverse[i] == '+x' && i != 0 && decimal == true) {

            upper = -((i -1) + .5)
            for (let j = x - 1; j >= upper ; j--) {
            plotCube(materialRed,  j, y, z)
            }
            x = upper
        } 

        if (gaugeReverse[i] == '-x' && i != 0 && decimal == false) {
            upper = i + .5
            for (let j = x - -1; j <= upper ; j++) {
                plotCube(materialRed, j, y, z)
            }
            x = upper
        } 

        if (gaugeReverse[i] == '-x' && i != 0 && decimal == true) {
            upper = (i - 1) + .5
            for (let j = x - -1; j <= upper ; j++) {
                plotCube(materialRed, j, y, z)
            }
            x = upper
        } 


        if (gaugeReverse[i] == '+y' && decimal == false) {
            upper = i + .5
            for (let j = y + 1; j <= upper ; j++) {
            plotCube(materialBlue, x, j, z)
            }
            y = upper
        }

        if (gaugeReverse[i] == '+y' && decimal == true) {
            upper = (i - 1) + .5
            for (let j = y + 1; j <= upper ; j++) {
            plotCube(materialBlue, x, j, z)
            }
            y = upper
        }



        if (gaugeReverse[i] == '-y' && decimal == false) {
            upper = -(i + .5)
            for (let j = y - 1; j >= upper ; j--) {
                plotCube(materialBlue, x, j, z)
            }
            y = upper
        }
        if (gaugeReverse[i] == '-y' && decimal == true) {
            upper = -((i - 1) + .5)
            for (let j = y - 1; j >= upper ; j--) {
                plotCube(materialBlue, x, j, z)
            }
            y = upper
        }

        if (gaugeReverse[i] == '+z' && decimal == false) {
            upper = i + .5
            for (let j = z + 1; j <= upper ; j++) {
            plotCube(materialYellow, x, y, j)
            }
            z = upper
    }
        if (gaugeReverse[i] == '+z' && decimal == true) {
            upper = (i - 1) + .5
            for (let j = z + 1; j <= upper ; j++) {
            plotCube(materialYellow, x, y, j)
            }
            z = upper
        }

        if (gaugeReverse[i] == '-z' && decimal == false) {
            upper = -(i + .5)
            for (let j = z - 1; j >= upper ; j--) {
            plotCube(materialYellow, x, y, j)
            }
            z = upper
        }

        if (gaugeReverse[i] == '-z' && decimal == true) {
            upper = -((i - 1) + .5)
            for (let j = z - 1; j >= upper ; j--) {
            plotCube(materialYellow, x, y, j)
            }
            z = upper
        }

        if (gaugeReverse[i] == '.' && decimal == false) {
            plotCube(materialWhite, x, y, z)
            decimal = true;
        }   
    scene.add( group );
}

}








//     let x = -0.5;
//     let y = .5;
//     let z = .5;
//     let gaugeReverse = gaugetype.reverse()
//     let upper;
//     let decimal = false;
//     for (let i = 0; i < gaugeReverse.length; i++)
//     {

//         if (gaugeReverse[i] == '-x' && i == 0 && decimal == false) {
//             x = .5
//             y = .5;
//             z = .5
//             plotCube(materialRed, x, y, z)
//         } 

//         if (gaugeReverse[i] == '+x' && i == 0 && decimal == false) {
//             x = -.5
//             y = .5;
//             z = .5
//             plotCube(materialRed, x, y, z)
//         } 

//         if (gaugeReverse[i] == '-z' && i == 0 && decimal == false) {
//             x = .5
//             y = .5;
//             z = -.5
//             plotCube(materialYellow, x, y, z)
//         } 

//         if (gaugeReverse[i] == '+z' && i == 0 && decimal == false) {
//             x = .5
//             y = .5;
//             z = .5
//             plotCube(materialYellow, x, y, z)
//         } 

//         if (gaugeReverse[i] == '-y' && i == 0 && decimal == false) {
//             x = .5
//             y = -.5;
//             z = .5
//             plotCube(materialBlue, x, y, z)
//         } 

//         if (gaugeReverse[i] == '+y' && i == 0 && decimal == false) {
//             x = .5
//             y = .5;
//             z = .5
//             plotCube(materialBlue, x, y, z)
//         } 

        

//         if (gaugeReverse[i] == '+x' && i != 0 && decimal == false) {

//             upper = -(i + .5)
//             for (let j = x - 1; j >= upper ; j--) {
//             plotCube(materialRed,  j, y, z)
//             }
//             x = upper
//         } 

//         if (gaugeReverse[i] == '+x' && i != 0 && decimal == true) {

//             upper = -((i -1) + .5)
//             for (let j = x - 1; j >= upper ; j--) {
//             plotCube(materialRed,  j, y, z)
//             }
//             x = upper
//         } 

//         if (gaugeReverse[i] == '-x' && i != 0 && decimal == false) {
//             upper = i + .5
//             for (let j = x - -1; j <= upper ; j++) {
//                 plotCube(materialRed, j, y, z)
//             }
//             x = upper
//         } 

//         if (gaugeReverse[i] == '-x' && i != 0 && decimal == true) {
//             upper = (i - 1) + .5
//             for (let j = x - -1; j <= upper ; j++) {
//                 plotCube(materialRed, j, y, z)
//             }
//             x = upper
//         } 


//         if (gaugeReverse[i] == '+y' && decimal == false) {
//             upper = i + .5
//             for (let j = y + 1; j <= upper ; j++) {
//             plotCube(materialBlue, x, j, z)
//             }
//             y = upper
//         }

//         if (gaugeReverse[i] == '+y' && decimal == true) {
//             upper = (i - 1) + .5
//             for (let j = y + 1; j <= upper ; j++) {
//             plotCube(materialBlue, x, j, z)
//             }
//             y = upper
//         }



//         if (gaugeReverse[i] == '-y' && decimal == false) {
//             upper = -(i + .5)
//             for (let j = y - 1; j >= upper ; j--) {
//                 plotCube(materialBlue, x, j, z)
//             }
//             y = upper
//         }
//         if (gaugeReverse[i] == '-y' && decimal == true) {
//             upper = -((i - 1) + .5)
//             for (let j = y - 1; j >= upper ; j--) {
//                 plotCube(materialBlue, x, j, z)
//             }
//             y = upper
//         }

//         if (gaugeReverse[i] == '+z' && decimal == false) {
//             upper = i + .5
//             for (let j = z + 1; j <= upper ; j++) {
//             plotCube(materialYellow, x, y, j)
//             }
//             z = upper
//     }
//         if (gaugeReverse[i] == '+z' && decimal == true) {
//             upper = (i - 1) + .5
//             for (let j = z + 1; j <= upper ; j++) {
//             plotCube(materialYellow, x, y, j)
//             }
//             z = upper
//         }

//         if (gaugeReverse[i] == '-z' && decimal == false) {
//             upper = -(i + .5)
//             for (let j = z - 1; j >= upper ; j--) {
//             plotCube(materialYellow, x, y, j)
//             }
//             z = upper
//         }

//         if (gaugeReverse[i] == '-z' && decimal == true) {
//             upper = -((i - 1) + .5)
//             for (let j = z - 1; j >= upper ; j--) {
//             plotCube(materialYellow, x, y, j)
//             }
//             z = upper
//         }

//         if (gaugeReverse[i] == '.' && decimal == false) {
//             plotCube(materialWhite, x, y, z)
//             decimal = true;
//         }   
//     scene.add( group );
// }
}

function plotCube(mType, xPos, yPos, zPos) {
    
    const cube1 = new THREE.Mesh( geometry, mType ); // node
    cube1.position.set(zPos, yPos , xPos);
    group.add( cube1 ); 
}


function plotSphere(mType, xPos, yPos, zPos) {
    const sphere1 = new THREE.Mesh( sphereGeo, mType ); // node
    sphere1.position.set(zPos, yPos , xPos);
    group.add( sphere1 ); 
}

function plotCy(mType, height, rotationX, rotationY, rotationZ, xPos, yPos, zPos) {
    const cyGeo = new THREE.CylinderGeometry( .04, .04, height, 10 );
    const cy1 = new THREE.Mesh( cyGeo, mType ); // node
    cy1.position.x = xPos
    cy1.position.y = yPos
    cy1.position.z = zPos
    // cy1.rotation.x = Math.PI/2
    cy1.rotation.x = rotationX
    cy1.rotation.y = rotationY
    cy1.rotation.z = rotationZ
    
    group.add( cy1 );
    scene.add( cylinder2 );
}





// const geometryblueC = new THREE.CylinderGeometry( .04, .04, 100, 10 );
// const materialblueC = new THREE.MeshBasicMaterial( {color: 0x2660A4, transparent: true, opacity: 0.8} );
// const cylinderC = new THREE.Mesh( geometryblue, materialblue );


// const geometryredC = new THREE.CylinderGeometry( .04, .04, 100, 10 );
// const materialredC = new THREE.MeshBasicMaterial( {color: 0xff2e2f, transparent: true, opacity: 0.8} );
// const cylinder2C = new THREE.Mesh( geometryred, materialred );


// const geometrygreenC = new THREE.CylinderGeometry( .04, .04, 100, 10 );
// const materialgreenC = new THREE.MeshBasicMaterial( {color: 0xffef36, transparent: true, opacity: 0.8} );
// const cylinder3C = new THREE.Mesh( geometrygreen, materialgreen );









// //  test geometries
// let geometry1
// let geometry2
// let geometry3
// let geometry4
// let geometry5
// let geometry6
// let geometry7
// let geometry8
// let plane1
// let plane2
// let plane3
// let plane4
// let plane5
// let plane6
// let plane7
// let plane8
// let material1
// let material2
// let material3
// let material4
// let material5
// let material6
// let material7
// let material8


// geometry1 = new THREE.PlaneGeometry( 1, 1 );
// material1 = new THREE.MeshBasicMaterial( {color: 0xb33e41, side: THREE.DoubleSide, transparent: true,  opacity: 0.5} );
// plane1 = new THREE.Mesh( geometry1, material1 );
// plane1.position.set(.5, .5, -1);
// scene.add( plane1 );


// geometry2 = new THREE.PlaneGeometry( 1, 1 );
// material2 = new THREE.MeshBasicMaterial( {color: 0xb33e41, side: THREE.DoubleSide, transparent: true,  opacity: 0.5} );
// plane2 = new THREE.Mesh( geometry2, material2 );
// plane2.position.set(.5, 1.5, -1);
// scene.add( plane2 );

// geometry3 = new THREE.PlaneGeometry( 1, 1 );
// material3 = new THREE.MeshBasicMaterial( {color: 0x28679e, side: THREE.DoubleSide, transparent: true,  opacity: 0.5} );
// plane3 = new THREE.Mesh( geometry3, material3 );
// plane3.position.set(.5, 2, -.5);
// plane3.rotation.x = Math.PI / 2;
// scene.add( plane3 );

// geometry4 = new THREE.PlaneGeometry( 1, 1 );
// material4 = new THREE.MeshBasicMaterial( {color: 0x28679e, side: THREE.DoubleSide, transparent: true,  opacity: 0.5} );
// plane4 = new THREE.Mesh( geometry4, material4 );
// plane4.position.set(1.5, 2, -.5);
// plane4.rotation.x = Math.PI / 2;
// scene.add( plane4 );

// geometry5 = new THREE.PlaneGeometry( 1, 1 );
// material5 = new THREE.MeshBasicMaterial( {color: 0x28679e, side: THREE.DoubleSide, transparent: true,  opacity: 0.5} );
// plane5 = new THREE.Mesh( geometry5, material5 );
// plane5.position.set(2.5, 2, -.5);
// plane5.rotation.x = Math.PI / 2;
// scene.add( plane5 );

// geometry6 = new THREE.PlaneGeometry( 1, 1 );
// material6 = new THREE.MeshBasicMaterial( {color: 0x3c7d40, side: THREE.DoubleSide, transparent: true,  opacity: 0.5} );
// plane6 = new THREE.Mesh( geometry6, material6 );
// plane6.position.set(3 , 1.5, -.5);
// plane6.rotation.y = Math.PI / 2;
// scene.add( plane6 );

// geometry7 = new THREE.PlaneGeometry( 1, 5 );
// material7 = new THREE.MeshBasicMaterial( {color: 0x3c7d40, side: THREE.DoubleSide, transparent: true,  opacity: 0.5} );
// plane7 = new THREE.Mesh( geometry7, material7 );
// plane7.position.set(3 , 1.5, 2.5);
// plane7.rotation.z = Math.PI / 2;
// plane7.rotation.y = Math.PI / 2;

// scene.add( plane7 );



// geometry8 = new THREE.PlaneGeometry( 1, 1 );
// material8 = new THREE.MeshBasicMaterial( {color: 0x4d4d4d, side: THREE.DoubleSide, transparent: true,  opacity: 0.5} );
// plane8 = new THREE.Mesh( geometry8, material8 );
// plane8.position.set(3.5 , 1.5, 5);


// scene.add( plane8 );


// function iterates over the gauge value and creates a plane for each value
// let mapping = () => {
//     for (var i = 0; i < gaugeList.length; i++)
//     {
//         if (gaugeList[i] != '.'){
//             if (gaugeList[i] == '+x'){
//                 geometry1 = new THREE.PlaneGeometry( 1, 1 );
//                 material1 = new THREE.MeshBasicMaterial( {color: 0xb33e41, side: THREE.DoubleSide, transparent: true,  opacity: 0.5} );
//                 plane1 = new THREE.Mesh( geometry1, material1 );
//                 plane1.position.set(.5, .5, cardinalCount);
//                 scene.add( plane1 );
//                 cardinalCount++;  // increments the cardinal count to make planes expand position
//             }
//             if (gaugeList[i] == '+y'){
//                 geometry2 = new THREE.PlaneGeometry( 1, 1 );
//                 material2 = new THREE.MeshBasicMaterial( {color: 0x28679e, side: THREE.DoubleSide, transparent: true,  opacity: 0.5} );
//                 plane2 = new THREE.Mesh( geometry2, material2 );
//                 plane2.position.set(.5, cardinalCount, .5);
//                 plane2.rotation.x = Math.PI/2;
//                 scene.add( plane2);
//                 cardinalCount++;
//             }
//             if (gaugeList[i] == '+z'){
//                 geometry3 = new THREE.PlaneGeometry( 1, 1 );
//                 material3 = new THREE.MeshBasicMaterial( {color: 0x3c7d40, side: THREE.DoubleSide, transparent: true,  opacity: 0.5} );
//                 plane3 = new THREE.Mesh( geometry3, material3 );
//                 plane3.position.set(cardinalCount, .5, .5);
//                 plane3.rotation.y = Math.PI / 2;
//                 scene.add( plane3 );
//                 cardinalCount++;
//             }
//             if (gaugeList[i] == '-x'){
//                 geometry4 = new THREE.PlaneGeometry( 1, 1 );
//                 material4 = new THREE.MeshBasicMaterial( {color: 0xb33e41, side: THREE.DoubleSide, transparent: true,  opacity: 0.5} );
//                 plane4 = new THREE.Mesh( geometry4, material4 );
//                 plane4.position.set(.5, .5, -1 * cardinalCount);
//                 scene.add( plane4 );
//                 cardinalCount++;
//             }
//             if (gaugeList[i] == '-y'){
//                 geometry5 = new THREE.PlaneGeometry( 1, 1 );
//                 material5 = new THREE.MeshBasicMaterial( {color: 0x28679e, side: THREE.DoubleSide, transparent: true,  opacity: 0.5} );
//                 plane5 = new THREE.Mesh( geometry5, material5 );
//                 plane5.position.set(.5, -1 * cardinalCount, .5);
//                 plane5.rotation.x = Math.PI / 2;
//                 scene.add( plane5 );
//                 cardinalCount++;
//             }
//             if (gaugeList[i] == '-z'){
//                 geometry6 = new THREE.PlaneGeometry( 1, 1 );
//                 material6 = new THREE.MeshBasicMaterial( {color: 0x3c7d40, side: THREE.DoubleSide, transparent: true,  opacity: 0.5} );
//                 plane6 = new THREE.Mesh( geometry6, material6 );
//                 plane6.position.set(-1 * cardinalCount, .5, .5);
//                 plane6.rotation.y = Math.PI / 2;
//                 scene.add( plane6 );
//                 cardinalCount++;
//             }
            

//         }
//     }
// }

// Gauge conversion
let gaugeConversion = () => {
    // reverses binary
    gauge = []
    let binaryValue = reverseString(binaryField.value)
    let axes = ['x', 'y', 'z']
    let axes2 = ['z', 'y', 'x']
    let axesCount = 0
    let axesCount2 = 0;
    let axesCount3 = 0;
    let gaugeWhole =[];
    let gaugeFloat =[];
    let finalGauge = [];

    // let floatValue = reverseString(splitString[1]);
    if (binaryField.value.includes('.')){
        gauge = []
        let splitString = binaryField.value.split(".");
        let wholeValue = reverseString(splitString[0]);
        let floatValue = splitString[1];
        if (binaryField.value[0] == '-'){
            for (var x = 0; x <= wholeValue.length; x++)
            {
                if (wholeValue[x] != '.'){
                    if (wholeValue[x] == '1'){
                        gaugeWhole.push('-' + axes[axesCount2%3]) // appends the axis to the value
                    }
                    if (wholeValue[x] == '0'){
                        gaugeWhole.push('+' + axes[axesCount2%3])
                    }
                    axesCount2++;
                }
                else{
                    gaugeWhole.push(wholeValue[x])
                }
            }
        }else{
            for (var x = 0; x <= wholeValue.length; x++)
            {
                if (wholeValue[x] != '.'){
                    if (wholeValue[x] == '1'){
                        gaugeWhole.push('+' + axes[axesCount2%3]) // appends the axis to the value
                    }
                    if (wholeValue[x] == '0'){
                        gaugeWhole.push('-' + axes[axesCount2%3])
                    }
                    axesCount2++;
                }
                else{
                    gaugeWhole.push(wholeValue[x])
                }
            }
        }

        if (binaryField.value[0] == '-'){
            for (var x = 0; x <= floatValue.length; x++)
            {
                if (floatValue[x] != '.'){
                    if (floatValue[x] == '1'){
                        gaugeFloat.push('-' + axes2[axesCount3%3]) // appends the axis to the value
                    }
                    if (floatValue[x] == '0'){
                        gaugeFloat.push('+' + axes2[axesCount3%3])
                    }
                    axesCount3++;
                }
                else{
                    gaugeFloat.push(floatValue[x])
                }
            }
        }else{
            for (var x = 0; x <= floatValue.length; x++)
            {
                if (floatValue[x] != '.'){
                    if (floatValue[x] == '1'){
                        gaugeFloat.push('+' + axes2[axesCount3%3]) // appends the axis to the value
                    }
                    if (floatValue[x] == '0'){
                        gaugeFloat.push('-' + axes2[axesCount3%3])
                    }
                    axesCount3++;
                }
                else{
                    gaugeFloat.push(floatValue[x])
                }
            }
        }
        gauge = (gaugeWhole.reverse()).concat('.', gaugeFloat);
        gaugeValue = gauge.join(" ");
        gaugeList = gauge
    }else{
        gauge = []
        if (binaryField.value[0] == '-'){
            for (var x = 0; x < binaryValue.length; x++)
            {
                if (binaryValue[x] != '.'){
                    if (binaryValue[x] == '1'){
                        gauge.push('-' + axes[axesCount%3]) // appends the axis to the value
                    }
                    if (binaryValue[x] == '0'){
                        gauge.push('+' + axes[axesCount%3])
                    }
                    axesCount++;
                }
                else{
                    gauge.push(binaryValue[x])
                }
            }
        } else{
            for (var x = 0; x < binaryValue.length; x++)
            {
                if (binaryValue[x] != '.'){
                    if (binaryValue[x] == '1'){
                        gauge.push('+' + axes[axesCount%3]) // appends the axis to the value
                    }
                    if (binaryValue[x] == '0'){
                        gauge.push('-' + axes[axesCount%3])
                    }
                    axesCount++;
                }
                else{
                    gauge.push(binaryValue[x])
                }
            }
        }
        gaugeValue = (gauge.reverse()).join(" ")   // reverses the array and joins the values
        gaugeList = gauge
    }


    // remove zero preceding decimal point

    if (gauge[1] === '.' && binaryField.value[0] == '0' || gauge[1] === '.' && binaryField.value[0] == '-' && binaryField.value[1] == '0'){
        gauge.shift();
        gaugeValue = gaugeValue.slice(2);
    }




    // if (binaryField.value[0]=="-"){


    // }
    // console.log(gaugeList)
  }


  let gaugeConversionB = () => {
    // reverses binary
    gauge2 = []
    let binaryValueB = reverseString(binaryFieldB.value)
    let axes = ['y', 'z', 'x']
    let axes2 = ['x', 'z', 'y']
    let axesCount = 0
    let axesCount2 = 0;
    let axesCount3 = 0;
    let gaugeWhole =[];
    let gaugeFloat =[];

    // let floatValue = reverseString(splitString[1]);
    if (binaryFieldB.value.includes('.')){
        gauge2 = []
        let splitString = binaryFieldB.value.split(".");
        let wholeValue = reverseString(splitString[0]);
        let floatValue = splitString[1];
        if (binaryFieldB.value[0] == '-'){
            for (var x = 0; x <= wholeValue.length; x++)
            {
                if (wholeValue[x] != '.'){
                    if (wholeValue[x] == '1'){
                        gaugeWhole.push('-' + axes[axesCount2%3]) // appends the axis to the value
                    }
                    if (wholeValue[x] == '0'){
                        gaugeWhole.push('+' + axes[axesCount2%3])
                    }
                    axesCount2++;
                }
                else{
                    gaugeWhole.push(wholeValue[x])
                }
            }
        }else{
            for (var x = 0; x <= wholeValue.length; x++)
            {
                if (wholeValue[x] != '.'){
                    if (wholeValue[x] == '1'){
                        gaugeWhole.push('+' + axes[axesCount2%3]) // appends the axis to the value
                    }
                    if (wholeValue[x] == '0'){
                        gaugeWhole.push('-' + axes[axesCount2%3])
                    }
                    axesCount2++;
                }
                else{
                    gaugeWhole.push(wholeValue[x])
                }
            }
        }

        if (binaryFieldB.value[0] == '-'){
            for (var x = 0; x <= floatValue.length; x++)
            {
                if (floatValue[x] != '.'){
                    if (floatValue[x] == '1'){
                        gaugeFloat.push('-' + axes2[axesCount3%3]) // appends the axis to the value
                    }
                    if (floatValue[x] == '0'){
                        gaugeFloat.push('+' + axes2[axesCount3%3])
                    }
                    axesCount3++;
                }
                else{
                    gaugeFloat.push(floatValue[x])
                }
            }
        }else{
            for (var x = 0; x <= floatValue.length; x++)
            {
                if (floatValue[x] != '.'){
                    if (floatValue[x] == '1'){
                        gaugeFloat.push('+' + axes2[axesCount3%3]) // appends the axis to the value
                    }
                    if (floatValue[x] == '0'){
                        gaugeFloat.push('-' + axes2[axesCount3%3])
                    }
                    axesCount3++;
                }
                else{
                    gaugeFloat.push(floatValue[x])
                }
            }
        }
        gauge2 = (gaugeWhole.reverse()).concat('.', gaugeFloat);
        gaugeValueB = gauge2.join(" ");
        gaugeListB = gauge2
    }else{
        gauge2 = []
        if (binaryFieldB.value[0] == '-'){
            for (var x = 0; x < binaryValueB.length; x++)
            {
                if (binaryValueB[x] != '.'){
                    if (binaryValueB[x] == '1'){
                        gauge2.push('-' + axes[axesCount%3]) // appends the axis to the value
                    }
                    if (binaryValueB[x] == '0'){
                        gauge2.push('+' + axes[axesCount%3])
                    }
                    axesCount++;
                }
                else{
                    gauge2.push(binaryValueB[x])
                }
            }
        } else{
            for (var x = 0; x < binaryValueB.length; x++)
            {
                if (binaryValueB[x] != '.'){
                    if (binaryValueB[x] == '1'){
                        gauge2.push('+' + axes[axesCount%3]) // appends the axis to the value
                    }
                    if (binaryValueB[x] == '0'){
                        gauge2.push('-' + axes[axesCount%3])
                    }
                    axesCount++;
                }
                else{
                    gauge2.push(binaryValueB[x])
                }
            }
        }
        gaugeValueB = (gauge2.reverse()).join(" ")   // reverses the array and joins the values
        gaugeListB = gauge2
    }

    if (gauge2[1] === '.' && binaryFieldB.value[0] == '0' || gauge2[1] === '.' && binaryFieldB.value[0] == '-' && binaryFieldB.value[1] == '0'){
        gauge2.shift();
        gaugeValueB = gaugeValueB.slice(2);
    }
  }

  let gaugeConversionC = () => {
    // reverses binary
    gauge3 = []
    let binaryValueC = reverseString(binaryFieldC.value)
    let axes = ['z', 'x', 'y']
    let axes2 = ['y', 'x', 'z']
    let axesCount = 0
    let axesCount2 = 0;
    let axesCount3 = 0;
    let gaugeWhole =[];
    let gaugeFloat =[];

    // let floatValue = reverseString(splitString[1]);
    if (binaryFieldC.value.includes('.')){
        gauge3 = []
        let splitString = binaryFieldC.value.split(".");
        let wholeValue = reverseString(splitString[0]);
        let floatValue = splitString[1];
        if (binaryFieldC.value[0] == '-'){
            for (var x = 0; x <= wholeValue.length; x++)
            {
                if (wholeValue[x] != '.'){
                    if (wholeValue[x] == '1'){
                        gaugeWhole.push('-' + axes[axesCount2%3]) // appends the axis to the value
                    }
                    if (wholeValue[x] == '0'){
                        gaugeWhole.push('+' + axes[axesCount2%3])
                    }
                    axesCount2++;
                }
                else{
                    gaugeWhole.push(wholeValue[x])
                }
            }
        }else{
            for (var x = 0; x <= wholeValue.length; x++)
            {
                if (wholeValue[x] != '.'){
                    if (wholeValue[x] == '1'){
                        gaugeWhole.push('+' + axes[axesCount2%3]) // appends the axis to the value
                    }
                    if (wholeValue[x] == '0'){
                        gaugeWhole.push('-' + axes[axesCount2%3])
                    }
                    axesCount2++;
                }
                else{
                    gaugeWhole.push(wholeValue[x])
                }
            }
        }

        if (binaryFieldC.value[0] == '-'){
            for (var x = 0; x <= floatValue.length; x++)
            {
                if (floatValue[x] != '.'){
                    if (floatValue[x] == '1'){
                        gaugeFloat.push('-' + axes2[axesCount3%3]) // appends the axis to the value
                    }
                    if (floatValue[x] == '0'){
                        gaugeFloat.push('+' + axes2[axesCount3%3])
                    }
                    axesCount3++;
                }
                else{
                    gaugeFloat.push(floatValue[x])
                }
            }
        }else{
            for (var x = 0; x <= floatValue.length; x++)
            {
                if (floatValue[x] != '.'){
                    if (floatValue[x] == '1'){
                        gaugeFloat.push('+' + axes2[axesCount3%3]) // appends the axis to the value
                    }
                    if (floatValue[x] == '0'){
                        gaugeFloat.push('-' + axes2[axesCount3%3])
                    }
                    axesCount3++;
                }
                else{
                    gaugeFloat.push(floatValue[x])
                }
            }
        }
        gauge3 = (gaugeWhole.reverse()).concat('.', gaugeFloat);
        gaugeValueC = gauge3.join(" ");
        gaugeListC = gauge3
    }else{
        gauge3 = []
        if (binaryFieldC.value[0] == '-'){
            for (var x = 0; x < binaryValueC.length; x++)
            {
                if (binaryValueC[x] != '.'){
                    if (binaryValueC[x] == '1'){
                        gauge3.push('-' + axes[axesCount%3]) // appends the axis to the value
                    }
                    if (binaryValueC[x] == '0'){
                        gauge3.push('+' + axes[axesCount%3])
                    }
                    axesCount++;
                }
                else{
                    gauge3.push(binaryValueC[x])
                }
            }
        } else{
            for (var x = 0; x < binaryValueC.length; x++)
            {
                if (binaryValueC[x] != '.'){
                    if (binaryValueC[x] == '1'){
                        gauge3.push('+' + axes[axesCount%3]) // appends the axis to the value
                    }
                    if (binaryValueC[x] == '0'){
                        gauge3.push('-' + axes[axesCount%3])
                    }
                    axesCount++;
                }
                else{
                    gauge3.push(binaryValueC[x])
                }
            }
        }
        gaugeValueC = (gauge3.reverse()).join(" ")   // reverses the array and joins the values
        gaugeListC = gauge3
    }
    if (gauge3[1] === '.' && binaryFieldC.value[0] == '0' || gauge3[1] === '.' && binaryFieldC.value[0] == '-' && binaryFieldC.value[1] == '0'){
        gauge3.shift();
        gaugeValueC = gaugeValueC.slice(2);
    }
}

let multBtn = document.getElementById("multiplication")
let addBtn = document.getElementById("addition")
let subBtn = document.getElementById("subtraction")
let divBtn = document.getElementById("division")
let rootBtn = document.getElementById("root")
let squareBtn = document.getElementById("square")

// let mult = () => {
//     calcState = false
//     if (multState == false){
//         multBtn.style.transform = 'scale(1.1)';
//         addBtn.style.transform = 'scale(0.9)';
//         subBtn.style.transform = 'scale(0.9)';
//         divBtn.style.transform = 'scale(0.9)';
//         rootBtn.style.transform = 'scale(0.9)';
//         squareBtn.style.transform = 'scale(0.9)';
//         multState = true;
//         addState = false;
//         squareState = false;
//         rootState = false;
//         subState = false;
//         divState = false;
//     }
//     else{
//         multBtn.style.transform = 'scale(1)';
//         addBtn.style.transform = 'scale(1)';
//         subBtn.style.transform = 'scale(1)';
//         divBtn.style.transform = 'scale(1)';
//         rootBtn.style.transform = 'scale(1)';
//         squareBtn.style.transform = 'scale(1)';
//         multState = false;
//         squareState = false;
//         addState = false;
//         subState = false;
//         rootState = false;
//         divState = false;

//     }
// }


// let add = () => {
//     calcState = false
//     if (addState == false){
//         addBtn.style.transform = 'scale(1.1)';
//         multBtn.style.transform = 'scale(0.9)';
//         subBtn.style.transform = 'scale(0.9)';
//         divBtn.style.transform = 'scale(0.9)';
//         rootBtn.style.transform = 'scale(0.9)';
//         squareBtn.style.transform = 'scale(0.9)';
//         addState = true;
//         multState = false;
//         subState = false;
//         squareState = false;
//         rootState = false;
//         divState = false;
//     }
//     else{
//         addBtn.style.transform = 'scale(1)';
//         multBtn.style.transform = 'scale(1)';
//         subBtn.style.transform = 'scale(1)';
//         divBtn.style.transform = 'scale(1)';
//         rootBtn.style.transform = 'scale(1)';
//         squareBtn.style.transform = 'scale(1)';
//         addState = false;
//         multState = false;
//         subState = false;
//         squareState = false;
//         divState = false;
//         rootState = false;

//     }
// }

// let sub = () => {
//     calcState = false
//     if (subState == false){
//         subBtn.style.transform = 'scale(1.1)';
//         multBtn.style.transform = 'scale(0.9)';
//         addBtn.style.transform = 'scale(0.9)';
//         divBtn.style.transform = 'scale(0.9)';
//         rootBtn.style.transform = 'scale(0.9)';
//         squareBtn.style.transform = 'scale(0.9)';
//         addState = false;
//         multState = false;
//         squareState = false;
//         subState = true;
//         divState = false;
//         rootState = false;
//     }
//     else{
//         subBtn.style.transform = 'scale(1)';
//         multBtn.style.transform = 'scale(1)';
//         addBtn.style.transform = 'scale(1)';
//         divBtn.style.transform = 'scale(1)';
//         rootBtn.style.transform = 'scale(1)';
//         squareBtn.style.transform = 'scale(1)';
//         addState = false;
//         multState = false;
//         squareState = false;
//         subState = false;
//         divState = false;
//         rootState = false;

//     }
// }


// let div = () => {
//     calcState = false
//     if (divState == false){
//         divBtn.style.transform = 'scale(1.1)';
//         multBtn.style.transform = 'scale(0.9)';
//         addBtn.style.transform = 'scale(0.9)';
//         subBtn.style.transform = 'scale(0.9)';
//         rootBtn.style.transform = 'scale(0.9)';
//         squareBtn.style.transform = 'scale(0.9)';
//         addState = false;
//         multState = false;
//         subState = false;
//         squareState = false;
//         divState = true;
//         rootState = false;
//     }
//     else{
//         divBtn.style.transform = 'scale(1)';
//         multBtn.style.transform = 'scale(1)';
//         addBtn.style.transform = 'scale(1)';
//         subBtn.style.transform = 'scale(1)';
//         rootBtn.style.transform = 'scale(1)';
//         squareBtn.style.transform = 'scale(1)';
//         addState = false;
//         multState = false;
//         subState = false;
//         divState = false;
//         squareState = false;
//         rootState = false;

//     }
// }

// let squareRoot = () => {
//     calcState = false
//     if (rootState == false){
//         rootBtn.style.transform = 'scale(1.1)';
//         multBtn.style.transform = 'scale(0.9)';
//         addBtn.style.transform = 'scale(0.9)';
//         subBtn.style.transform = 'scale(0.9)';
//         divBtn.style.transform = 'scale(0.9)';
//         squareBtn.style.transform = 'scale(0.9)';
//         addState = false;
//         multState = false;
//         subState = false;
//         divState = false;
//         rootState = true;
//         squareState = false;
//     }
//     else{
//         rootBtn.style.transform = 'scale(1)';
//         multBtn.style.transform = 'scale(1)';
//         addBtn.style.transform = 'scale(1)';
//         subBtn.style.transform = 'scale(1)';
//         divBtn.style.transform = 'scale(1)';
//         squareBtn.style.transform = 'scale(1)';
//         addState = false;
//         multState = false;
//         subState = false;
//         divState = false;
//         rootState = false;
//         squareState = false;

//     }
// }

// let squared = () => {
//     calcState = false
//     if (squareState == false){
//         squareBtn.style.transform = 'scale(1.1)';
//         multBtn.style.transform = 'scale(0.9)';
//         addBtn.style.transform = 'scale(0.9)';
//         subBtn.style.transform = 'scale(0.9)';
//         divBtn.style.transform = 'scale(0.9)';
//         rootBtn.style.transform = 'scale(0.9)';
//         addState = false;
//         multState = false;
//         subState = false;
//         divState = false;
//         rootState = false;
//         squareState = true;
//     }
//     else{
//         squareBtn.style.transform = 'scale(1)';
//         multBtn.style.transform = 'scale(1)';
//         addBtn.style.transform = 'scale(1)';
//         subBtn.style.transform = 'scale(1)';
//         divBtn.style.transform = 'scale(1)';
//         rootBtn.style.transform = 'scale(1)';
//         addState = false;
//         multState = false;
//         subState = false;
//         divState = false;
//         rootState = false;
//         squareState = false;

//     }
// }

// /need to changemapping for negative numbers and imaginary numbers
// let multState = false;
// let addState = false;
// let subState = false;
// let divState = false;
// let rootState = false;
// let squareState = false;




let add = () => {
    multState = false;
    subState = false;
    divState = false;
    rootState = false;
    squareState = false;
    if(addState == false){
        let a = decimalField.value;
        let b = decimalFieldB.value;
        let sum1 = parseFloat(a) + parseFloat(b);
        document.getElementById("decimalResult").value = sum1;
        resultPop(sum1)
        group.remove(...group.children)
        mapping(gauge3)
        addState = true;
    }else if(addState == true){
        let c = decimalFieldB.value;
        let d = decimalResult.value;
        let sum2 = parseFloat(c) + parseFloat(d);
        document.getElementById("decimalResult").value = sum2;
        resultPop(sum2)
        group.remove(...group.children)
        mapping(gauge3) 
    }


}


let sub = () => {
    multState = false;
    addState = false;
    divState = false;
    rootState = false;
    squareState = false;
    if(subState == false){
        let a = decimalField.value;
        let b = decimalFieldB.value;
        let sum1 = parseFloat(a) - parseFloat(b);
        document.getElementById("decimalResult").value = sum1;
        resultPop(sum1)
        group.remove(...group.children)
        mapping(gauge3)
        subState = true;
    } else if(subState == true){
        let c = decimalFieldB.value;
        let d = decimalResult.value;
        let sum2 = parseFloat(d) - parseFloat(c);
        document.getElementById("decimalResult").value = sum2;
        resultPop(sum2)
        group.remove(...group.children)
        mapping(gauge3) 
    }
}

let mult = () => {
    addState = false;
    subState = false;
    divState = false;
    rootState = false;
    squareState = false;

    if(multState == false){
        let a = decimalField.value;
        let b = decimalFieldB.value;
        let product1 = parseFloat(a) * parseFloat(b);
        document.getElementById("decimalResult").value = product1;
        resultPop(product1)
        group.remove(...group.children)
        mapping(gauge3) 
    } else if(multState == true){
        let c = decimalFieldB.value;
        let d = decimalResult.value;
        let product2 = parseFloat(c) * parseFloat(d);
        document.getElementById("decimalResult").value = product2;
        resultPop(product2)
        group.remove(...group.children)
        mapping(gauge3) 
    }
    multState = true;
}

let div = () => {

    addState = false;
    subState = false;
    multState = false;
    rootState = false;
    squareState = false;

    if (divState == false){
        let a = decimalField.value;
        let b = decimalFieldB.value;
        let product1 = parseFloat(a) / parseFloat(b);
        document.getElementById("decimalResult").value = product1;
        resultPop(product1)
        group.remove(...group.children)
        mapping(gauge3) 
    }else if (divState == true){
        let c = decimalFieldB.value;
        let d = decimalResult.value;
        let product2 = parseFloat(d) / parseFloat(c)
        document.getElementById("decimalResult").value = product2;
        resultPop(product2)
        group.remove(...group.children)
        mapping(gauge3) 
    }
    divState = true;
}

let squareRoot = () => {
    addState = false;
    divState = false;
    subState = false;
    multState = false;
    squareState = false;

    if (rootState == false){
        let a = decimalField.value;
        let product1 = Math.sqrt(a);
        document.getElementById("decimalResult").value = product1;
        resultPop(product1)
        group.remove(...group.children)
        mapping(gauge3) 
    } else if (rootState == true){
        let d = decimalResult.value;
        let product2 = Math.sqrt(d);
        document.getElementById("decimalResult").value = product2;
        resultPop(product2)
        group.remove(...group.children)
        mapping(gauge3) 
    }
    rootState = true;
}

let squared = () => {

    addState = false;
    divState = false;
    subState = false;
    multState = false;
    rootState = false;

    if (squareState == false){
        let a = decimalField.value;
        let product1 = a * a
        document.getElementById("decimalResult").value = product1;
        resultPop(product1)
        group.remove(...group.children)
        mapping(gauge3)
    } else if (squareState == true){
        let d = decimalResult.value;
        let product2 = d * d;
        document.getElementById("decimalResult").value = product2;
        resultPop(product2)
        group.remove(...group.children)
        mapping(gauge3) 
    }

    squareState = true;

}


document.getElementById("multiplication").addEventListener("click", mult);
document.getElementById("addition").addEventListener("click", add);
document.getElementById("subtraction").addEventListener("click", sub);
document.getElementById("division").addEventListener("click", div);
document.getElementById("root").addEventListener("click", squareRoot);
document.getElementById("square").addEventListener("click", squared);


// let calculate = () => {
    
//     if (addState == true && calcState == false){
//         let a = decimalField.value;
//         let b = decimalFieldB.value;
//         let sum1 = parseFloat(a) + parseFloat(b);
//         document.getElementById("decimalResult").value = sum1;
//         resultPop(sum1)
//         group.remove(...group.children)
//         mapping(gauge3) 
//         calcState = true;
//     } else if (addState == true && calcState == true){
//         let c = decimalFieldB.value;
//         let d = decimalResult.value;
//         let sum2 = parseFloat(c) + parseFloat(d);
//         document.getElementById("decimalResult").value = sum2;
//         resultPop(sum2)
//         group.remove(...group.children)
//         mapping(gauge3) 
//     }

//     if (subState == true && calcState == false){
//         let a = decimalField.value;
//         let b = decimalFieldB.value;
//         let sum1 = parseFloat(a) - parseFloat(b);
//         document.getElementById("decimalResult").value = sum1;
//         resultPop(sum1)
//         group.remove(...group.children)
//         mapping(gauge3) 
//         calcState = true;
//     } else if (subState == true && calcState == true){
//         let c = decimalFieldB.value;
//         let d = decimalResult.value;
//         let sum2 = parseFloat(d) - parseFloat(c);
//         document.getElementById("decimalResult").value = sum2;
//         resultPop(sum2)
//         group.remove(...group.children)
//         mapping(gauge3) 
//     }

//     if (multState == true && calcState == false){
//         let a = decimalField.value;
//         let b = decimalFieldB.value;
//         let product1 = parseFloat(a) * parseFloat(b);
//         document.getElementById("decimalResult").value = product1;
//         resultPop(product1)
//         group.remove(...group.children)
//         mapping(gauge3) 
//         calcState = true;
//     } else if (multState == true && calcState == true){
//         let c = decimalFieldB.value;
//         let d = decimalResult.value;
//         let product2 = parseFloat(c) * parseFloat(d);
//         document.getElementById("decimalResult").value = product2;
//         resultPop(product2)
//         group.remove(...group.children)
//         mapping(gauge3) 
//     }

//     if (divState == true && calcState == false){
//         let a = decimalField.value;
//         let b = decimalFieldB.value;
//         let product1 = parseFloat(a) / parseFloat(b);
//         document.getElementById("decimalResult").value = product1;
//         resultPop(product1)
//         group.remove(...group.children)
//         mapping(gauge3) 
//         calcState = true;
//     } else if (divState == true && calcState == true){
//         let c = decimalFieldB.value;
//         let d = decimalResult.value;
//         let product2 = parseFloat(d) / parseFloat(c)
//         document.getElementById("decimalResult").value = product2;
//         resultPop(product2)
//         group.remove(...group.children)
//         mapping(gauge3) 
//     }

//     if (rootState == true && calcState == false){
//         let a = decimalField.value;
//         let product1 = Math.sqrt(a);
//         document.getElementById("decimalResult").value = product1;
//         resultPop(product1)
//         group.remove(...group.children)
//         mapping(gauge3) 
//         calcState = true;
//     } else if (rootState == true && calcState == true){
//         let d = decimalResult.value;
//         let product2 = Math.sqrt(d);
//         document.getElementById("decimalResult").value = product2;
//         resultPop(product2)
//         group.remove(...group.children)
//         mapping(gauge3) 
//     }


//     if (squareState == true && calcState == false){
//         let a = decimalField.value;
//         let product1 = a * a
//         document.getElementById("decimalResult").value = product1;
//         resultPop(product1)
//         group.remove(...group.children)
//         mapping(gauge3) 
//         calcState = true;
//     } else if (squareState == true && calcState == true){
//         let d = decimalResult.value;
//         let product2 = d * d;
//         document.getElementById("decimalResult").value = product2;
//         resultPop(product2)
//         group.remove(...group.children)
//         mapping(gauge3) 
//     }
    

// }

// document.getElementById("calcBtn").addEventListener("click", calculate);

let resultPop = (result) => {
    let binaryValueC = result.toString(2)
    document.getElementById("binaryResult").value = binaryValueC;
    gaugeConversionC();
    document.getElementById("gaugeResult").value = gaugeValueC;
    inputState = "C";
}

// let count = 1;
// controls.autoRotate = true;


let prePop = () => {
    let result1 = 26601087768;
    document.getElementById("decimalResult").value = result1;
    resultPop(result1)
    group.remove(...group.children)
    mapping(gauge3) 
    inputState = "C";
    // count++;
}

prePop();




// let displayPrimes = () => {
//     const primes = [0.00000000000000000, 0.04761904761904762, 0.09523809523809524, 0.14285714285714285, 0.19047619047619047, 0.23809523809523808, 0.2857142857142857, 0.3333333333333333, 0.38095238095238093, 0.42857142857142855, 0.47619047619047616, 0.5238095238095238, 0.5714285714285714, 0.6190476190476191, 0.6666666666666666, 0.7142857142857143, 0.7619047619047619, 0.8095238095238095, 0.8571428571428571, 0.9047619047619048, 0.9523809523809523, 1.0000000000000002, 1.0476190476190477, 1.0952380952380953, 1.1428571428571432, 1.1904761904761905, 1.2380952380952386, 1.2857142857142868, 1.3333333333333335, 1.3809523809523812, 1.4285714285714288, 1.4761904761904762, 1.5238095238095237, 1.571428571428571, 1.619047619047619, 1.6666666666666665, 1.7142857142857144, 1.7619047619047618, 1.8095238095238093, 1.8571428571428568, 1.9047619047619053, 1.9523809523809526, 2.0000000000000004, 2.047619047619048, 2.0952380952380953, 2.1428571428571432, 2.1904761904761904, 2.238095238095238, 2.285714285714286, 2.3333333333333335, 2.380952380952381, 2.4285714285714285, 2.4761904761904763, 2.523809523809524, 2.571428571428571, 2.619047619047619, 2.6666666666666665, 2.7142857142857144, 2.7619047619047616, 2.8095238095238093, 2.8571428571428568, 2.9047619047619053, 2.9523809523809526, 3.0000000000000004, 3.047619047619048, 3.0952380952380953, 3.142857142857143, 3.1904761904761905, 3.238095238095238, 3.285714285714286, 3.333333333333333, 3.380952380952381, 3.4285714285714285, 3.4761904761904763, 3.523809523809524, 3.571428571428571, 3.619047619047619, 3.6666666666666665, 3.7142857142857142, 3.7619047619047616, 3.8095238095238093, 3.857142857142857, 3.904761904761905, 3.9523809523809526, 4.0000000000000004]
    
//     for (let i = 0; i < primes.length; i++) {
//         setTimeout( () => {
//         let result1 = primes[i];
//         document.getElementById("decimalResult").value = result1;
//         resultPop(result1);
//         group.remove(...group.children);
//         mapping(gauge3);
//       }, 200 * i);
// }
// }

// displayPrimes();


// if (count <= 100){
//     setInterval(inverse(), 3000);
// }

// let inverse = () => {
//     let result1 = 1 / count;
//     document.getElementById("decimalResult").value = result1;
//     resultPop(result1)
//     group.remove(...group.children)
//     mapping(gauge3) 
//     count++;
// }

let count = 1;

let divideCount = () => {
    // takes decimal field

    let a = decimalField.value;
    let result1 = count / a;
    document.getElementById("numerator").innerText = count;
    document.getElementById("denominator").innerText = a;
    resultPop(result1)
    group.remove(...group.children)
    mapping(gauge3) 
    count += 1;
    // let result1 = 1 / count;
    // document.getElementById("decimalResult").value = result1;
    // resultPop(result1)
    // group.remove(...group.children)
    // mapping(gauge3) 
    // count++;
}



// document.getElementById("functBtn").addEventListener("click", divideCount);



let euler = () => {
    multState = false;
    addState = false;
    subState = false;
    divState = false;
    rootState = false;
    squareState = false;
    document.getElementById("decimalA").value = Math.E;
    let binaryValueC = Math.E.toString(2)
    document.getElementById("binaryA").value = binaryValueC;
    gaugeConversion();
    document.getElementById("gaugeA").value = gaugeValue;
    
    group.remove(...group.children)
    mapping(gauge) 
}

document.getElementById("euler").addEventListener("click", euler);

let piFunct = () => {
    multState = false;
    addState = false;
    subState = false;
    divState = false;
    rootState = false;
    squareState = false;
    document.getElementById("decimalA").value = Math.PI;
    let binaryValueC = Math.PI.toString(2)
    document.getElementById("binaryA").value = binaryValueC;
    gaugeConversion();
    document.getElementById("gaugeA").value = gaugeValue;
    
    group.remove(...group.children)
    mapping(gauge) 
}



document.getElementById("pi").addEventListener("click", piFunct);


let clearFunct = () => {
    document.getElementById("decimalA").value = "";
    document.getElementById("binaryA").value = "";
    document.getElementById("gaugeA").value =  "";

    document.getElementById("decimalB").value = "";
    document.getElementById("binaryB").value = "";
    document.getElementById("gaugeB").value =  "";

    document.getElementById("decimalResult").value = "";
    document.getElementById("binaryResult").value = "";
    document.getElementById("gaugeResult").value =  "";
    
    group.remove(...group.children);
    conGroup.remove(...conGroup.children);
}



document.getElementById("clear").addEventListener("click", clearFunct);

document.body.style.overflow = 'hidden';

let toggleTutorial = () => {
    var x = document.getElementById("tutorial_page");
    var y = document.getElementById("calculator_page");
    x.style.display = "none";
    tour.cancel();
    if (x.style.display === "none") {
      document.documentElement.style.background = '#32393C';
      document.body.style.overflow = 'auto';
      document.body.style.background = '#32393C';
    
      x.style.display = "block";
      y.style.display = "none";
    } else {
      document.documentElement.style.background = '#EAEAEA';
      document.body.style.background = '#EAEAEA';
      x.style.display = "none";
      y.style.display = "block";
    }
}



document.getElementById("toggleT").addEventListener("click", toggleTutorial);



let toggleTutorial2 = () => {
    var x = document.getElementById("tutorial_page");
    var y = document.getElementById("calculator_page");
    y.style.display = "none";
    tour.cancel();
    if (y.style.display === "none") {
      document.documentElement.style.background = '#EAEAEA'; 
      document.body.style.background = '#EAEAEA';
      document.body.style.overflow = 'hidden';
      y.style.display = "block";
      x.style.display = "none";
    } else {
      document.documentElement.style.background = '#32393C';
      document.body.style.background = '#32393C';
      document.body.style.overflow = 'auto';
      y.style.display = "none";
      x.style.display = "block";
    }
}

document.getElementById("toggleT2").addEventListener("click", toggleTutorial2);
document.getElementById("toggleT3").addEventListener("click", toggleTutorial2);

let scrollRef = 0;

window.addEventListener('scroll', function() {
  // increase value up to 10, then refresh AOS
  scrollRef <= 10 ? scrollRef++ : AOS.refresh();
});

// let collatz = () => {
//     let a = decimalField.value;
//     if (a % 2 == 0){
//         a = a / 2;
//     } else {
//         a = (a * 3) + 1;
//     }
//     document.getElementById("decimalA").value = a;
//     resultPop(a)
//     group.remove(...group.children)
//     mapping(gauge3)
// }




// document.getElementById("collatzBtn").addEventListener("click", collatz);



tick()  // runs the tick function